import React from 'react'

import Layout from '../../components/layout'
import { SmallTitlePage } from '../../components/common/smallTitlePage'
import { BeautyTreatments } from '../../components/common/beautyTreatments'
import { useStaticQuery, graphql } from 'gatsby'
import Seo from '../../components/seo'


const TorunNails = () => {

    const data = useStaticQuery(graphql`
    {
        allContentfulTorunStylizacjaPaznokci(sort: {fields: kolejnosc}) {
          nodes {
            id
            zdjecieGlowne {
                gatsbyImageData(layout: CONSTRAINED)
            }
            nazwaZabiegu
            opisZabiegu {
              opisZabiegu
            }
            slug
          }
        },

        file(relativePath: {eq: "bodymedgroup-bydgoszcz.jpg"}) {
          id
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              
            )
          }
        }
      }
  `)

  const treatments = data.allContentfulTorunStylizacjaPaznokci.nodes

    return (
        <Layout torun>
            <Seo
              title="Stylizacja paznokci Torun"
              description="Stylizacja paznokci w BodyMed Group Torun"
              url="https://bodymedgroup.pl/torun-stylizacja-paznokci"
            />
            <SmallTitlePage 
              title='Stylizacja Paznokci' 
            />
            <BeautyTreatments treatments={treatments}/>
        </Layout>
    )
}

export default TorunNails
